import React, { Fragment } from "react";
import { Gray } from "./components";
import { CharInfo } from "./types";

const THIN_SPACE = " ";

export const ACCESS_KEY = "697d8c2d31272569b6141754795333f6d6b9ae3d";

export const URL = `https://emoji-api.com/emojis?access_key=${ACCESS_KEY}`;

export const rateInfoList: CharInfo[] = [
    "☆☆☆☆☆",
    "★☆☆☆☆",
    "★★☆☆☆",
    "★★★☆☆",
    "★★★★☆",
    "★★★★★",
].map((rating, index) => ({
    id: `${index}`,
    char: rating,
    textContent: `${index}★`,
    title: `Hodnocení ${rating}`,
    top: -0.05,
}));

export const charInfoList: CharInfo[] = [
    { char: "–", title: "Pomlčka" },
    { top: -0.02, char: "„“", textContent: `„${THIN_SPACE}“`, title: "Uvozovky" },
    { top: 0.02, char: "•", title: "Puntík" },
    { left: 0.01, top: -0.08, char: "×", title: "Násobení" },
    { left: 0.02, char: "‰", title: "Promile" },
    { top: -0.08, char: "μ", title: "Mikro" },
    { char: "…", title: "Výpustka" },
    { char: "✓", title: "Dokončeno" },
    { top: -0.1, char: "@", title: "Zavináč" },
    { top: -0.05, char: "[]", textContent: `[${THIN_SPACE}]`, title: "Hranaté závorky" },
    { top: -0.05, char: "{}", textContent: `{${THIN_SPACE}}`, title: "Složené závorky" },
    { char: "<>", title: "Špičaté závorky" },
    { top: -0.1, char: "←", title: "Šipka doleva" },
    { top: -0.1, char: "→", title: "Šipka doprava" },
    { top: -0.1, char: "↑", title: "Šipka nahoru" },
    { top: -0.1, char: "↓", title: "Šipka dolů" },
    { char: "²", textContent: <Fragment><Gray>x</Gray>²</Fragment>, title: "Druhá mocnina" },
    { char: "³", textContent: <Fragment><Gray>y</Gray>³</Fragment>, title: "Třetí mocnina" },
    { char: "₂", textContent: <Fragment><Gray>H</Gray>₂<Gray>O</Gray></Fragment>, title: "Dolní index čísla 2" },
    { char: "₃", textContent: <Fragment><Gray>NO</Gray>₃</Fragment>, title: "Dolní index čísla 3" },
    { top: -0.08, char: "|", title: "Svislítko" },
    { char: "#", title: "Mřížka" },
    { top: -0.08, char: "§", title: "Paragraf" },
    { char: "°", textContent: <Fragment><Gray>0</Gray>°</Fragment>, title: "Stupeň" },
    { char: "$", title: "Dolar" },
    { char: "¼", title: "Čtvrtina" },
    { char: "⅓", title: "Třetina" },
    { char: "½", title: "Polovina" },
    { char: "/", title: "Lomítko" },
    { char: "\\", title: "Zpětné lomítko" },
    { char: "~", title: "Tilda" },
    { char: "≈", title: "Dvojitá vlnovka (přibližně stejné jako...)" },
    { char: "≋", title: "Trojitá vlnovka (kongruence)" },
    { char: THIN_SPACE, textContent: <Fragment><Gray>1</Gray>{THIN_SPACE}<Gray>l</Gray></Fragment>, title: "Úzka mezera" },
    { left: -0.05, char: "€", title: "Euro" },
    { char: "'", title: "Strojopisný apostrof" },
    { char: "’", title: "Typografický apostrof" },
    { top: -0.05, char: " ", textContent: <Fragment><Gray>a&nbsp;s</Gray></Fragment>, title: "Nezlomitelná mezera" },
    { top: 0.05, char: "⌘", title: "Command (macOS)" },
    { char: "⇧", title: "Shift (macOS)" },
    { top: 0.03, char: "⌥", title: "Option (macOS)" },
    { char: "⌃", title: "Control (macOS)" },
    { char: "±", title: "Plus mínus" },
    { char: ";", title: "Středník" },
    { char: "∞", title: "Nekonečno" },
    { char: "£", title: "Libra" },
    { char: "≤", title: "Menší než nebo rovno" },
    { char: "≥", title: "Větší než nebo rovno" },
    { char: "⠀", title: "Prázdný znak", textContent: <Fragment><Gray>□</Gray></Fragment> },
    { char: "∑", title: "Součet" },
    { char: "≠", title: "Nerovná se" },
    { char: "÷", title: "Děleno" },
    { char: "⏎", title: "Enter" },
    { char: "√", title: "Odmocnina" },
].map(data => ({
    id: data.char,
    ...data,
}));
